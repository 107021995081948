<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="350"
    @keydown.esc="cancel"
  >
    <v-card>
      <!--<v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>-->
      <v-card-title class="text-h5"> {{ title }} </v-card-title>
      <v-card-text
        class="text-h6"
        v-show="!!message"
        v-html="message"
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!options.noconfirm"
          color="green darken-1"
          text
          @click.native="cancel"
        >
            Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click.native="agree"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "ConfirmDlg",
    data() {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
          color: "green darken-1",
          width: 400,
          zIndex: 200,
          noconfirm: false,
        },
      };
    },

    methods: {
      open(title, message, options) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      agree() {
        this.resolve(true);
        this.dialog = false;
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
      },
    },
  };
</script>
